import React, {Suspense, useEffect} from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import Router from './router.tsx'
import {HelmetProvider} from 'react-helmet-async'
import Loading from './components/Loading.tsx'
import ErrorPage from './components/ErrorPage.tsx'
import GlobalWrapper from './GlobalWrapper.tsx'
import {Toaster} from './components/ui/toaster.tsx'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
})

export const AppRoot: React.FC = () => {
    const [isTabbing, setIsTabbing] = React.useState(false)

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Tab') {
                setIsTabbing(true)
            }
            if (event.key === 'Enter' && isTabbing) {
                document.activeElement?.dispatchEvent(new Event('click', {bubbles: true}))
            }
        }
        const handleMouseDown = () => {
            setIsTabbing(false)
        }
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [isTabbing])

    useEffect(() => {
        if (isTabbing) {
            document.body.classList.add('tabAccess')
        } else {
            document.body.classList.remove('tabAccess')
        }
    }, [isTabbing])

    return (
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <Sentry.ErrorBoundary
                    fallback={
                        <Suspense fallback={<Loading />}>
                            <GlobalWrapper>
                                <ErrorPage error="An error occurred" />
                            </GlobalWrapper>
                        </Suspense>
                    }
                >
                    <Suspense fallback={<Loading />}>
                        <Router />
                        <Toaster />
                    </Suspense>
                </Sentry.ErrorBoundary>
            </QueryClientProvider>
        </HelmetProvider>
    )
}
