import React from 'react'
import ReactDOM from 'react-dom/client'
import {AppRoot} from './AppRoot'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
import {PostHogProvider} from 'posthog-js/react'

posthog.init(_POSTHOG_API_KEY_, {
    api_host: 'https://hypertunnel.greenchipdata.com',
    person_profiles: 'always',
})

Sentry.init({
    dsn: 'https://7461619f64ac57a2f98951461173c438@o4508344673828864.ingest.us.sentry.io/4508471569088512',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.greenchipdata\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== 'development',
})

ReactDOM.createRoot(document.getElementById('root')!, {
    onUncaughtError: Sentry.reactErrorHandler(),
    onCaughtError: Sentry.reactErrorHandler(),
    onRecoverableError: Sentry.reactErrorHandler(),
}).render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <AppRoot />
        </PostHogProvider>
    </React.StrictMode>,
)
