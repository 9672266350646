import {TinyColor} from '@ctrl/tinycolor'
import {DefaultColors} from 'tailwindcss/types/generated/colors'

export type TailwindColor = `${keyof DefaultColors}-${keyof DefaultColors['slate']}`

// Colours for chart elements
export const chartColourScheme = [
    '#4E6CA3',
    '#D75C5E',
    '#f9cd47',
    '#9059ab',
    '#9CB2BE',
    '#5B938F',
    '#8BB0CF',
    '#BED2D8',
    '#B1D6C6',
    '#FFBD88',
    '#A8C5DD',
    '#A3A3A3',
    '#BABABA',
]
// Colour for Chart element borders, 10% darker
export const borderColourScheme = chartColourScheme.map(c =>
    new TinyColor(c).darken(15).toHexString(),
)

export const cycleColor = (i: number, colorScheme = chartColourScheme) => {
    return colorScheme[i % colorScheme.length]
}

export const namedColours = {
    green: '#40a073',
    red: '#e33b3d',
    purple: '#a555ce',
    blue: '#3c6bc3',
    yellow: '#f9cd47',
}
