import {SearchPayload} from '~/api/types'
import {Popover, PopoverAnchor, PopoverContent} from '../ui/popover'
import {Button} from '../ui/button'
import {Plus, XIcon} from 'lucide-react'
import {useState} from 'react'
import {useGet} from '~/api/query'
import {FilterByMarketCap} from './filterByMarketCap'
import {FilterBySector} from './filterBySector'

export const useSearchConfig = () => {
    const {data} = useGet({endpoint: '/search/config'})
    return data
}

const FilterCard = ({
    label,
    isActive,
    onFocus,
    clearFilter,
    isFocused,
    clearFocus,
    filterControls,
}: {
    label: string
    isActive: boolean
    onFocus: () => void
    clearFilter: () => void
    isFocused: boolean
    clearFocus: () => void
    filterBy: SearchPayload
    setFilterBy: (payload: SearchPayload) => void
    filterControls: React.ReactNode
}) => {
    return (
        <Popover open={isFocused} onOpenChange={() => clearFocus()}>
            <PopoverAnchor asChild>
                <Button
                    className="flex w-full max-w-36 justify-between rounded-sm p-3 text-base font-medium sm:max-w-44"
                    variant={isActive ? 'default' : 'secondary'}
                    size="lg"
                    onFocus={onFocus}
                    onMouseEnter={onFocus}
                    onClick={e => {
                        e.preventDefault()
                        if (isActive) {
                            clearFilter()
                        } else {
                            onFocus()
                        }
                    }}
                >
                    <span>{label}</span>{' '}
                    {!isActive ? <Plus className="w-4" /> : <XIcon className="w-4" />}
                </Button>
            </PopoverAnchor>
            <PopoverContent
                side="bottom"
                align="start"
                sideOffset={10}
                className="max-h-96 w-full max-w-md overflow-y-auto p-0 pb-4"
            >
                <h4 className="bg-popover sticky top-0 border-b px-4 pb-2 pt-4">
                    Filter by {label}
                </h4>
                <div className="px-4">{filterControls}</div>
            </PopoverContent>
        </Popover>
    )
}

export const Filter = ({
    filterBy,
    setFilterBy,
}: {
    filterBy: SearchPayload
    setFilterBy: (payload: SearchPayload) => void
}) => {
    const [focus, setFocus] = useState<'market_cap' | 'sector' | 'metric_shape'>()

    return (
        <div>
            <h2>Filter</h2>
            <div className="flex gap-4">
                <FilterCard
                    label="Sector"
                    isActive={!!filterBy.sector_filter}
                    onFocus={() => setFocus('sector')}
                    clearFilter={() => {
                        setFilterBy({...filterBy, sector_filter: undefined})
                        setFocus(undefined)
                    }}
                    isFocused={focus === 'sector'}
                    clearFocus={() => setFocus(undefined)}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    filterControls={
                        <FilterBySector filterBy={filterBy} setFilterBy={setFilterBy} />
                    }
                />
                <FilterCard
                    label="Market Cap"
                    isActive={!!filterBy.market_cap_filter}
                    onFocus={() => setFocus('market_cap')}
                    clearFilter={() => {
                        setFilterBy({...filterBy, market_cap_filter: undefined})
                        setFocus(undefined)
                    }}
                    isFocused={focus === 'market_cap'}
                    clearFocus={() => setFocus(undefined)}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    filterControls={
                        <FilterByMarketCap filterBy={filterBy} setFilterBy={setFilterBy} />
                    }
                />
            </div>
        </div>
    )
}
