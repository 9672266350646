import {QueryErrorResetBoundary} from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import {ErrorInfo, Suspense} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import Loading from './Loading'
import {useLocation} from 'react-router-dom'
import ErrorPage from './ErrorPage'
import {ReportedAPIError} from '~/api/query'

export const SuspenseAndBoundary: React.FC<{children: React.ReactNode}> = ({children}) => {
    const location = useLocation()

    const onError = (error: Error, info: ErrorInfo) => {
        if (error instanceof ReportedAPIError) {
            // This error's already been reported to sentry
            return
        }
        Sentry.captureException(error, {extra: info as Record<string, string>})
    }

    return (
        <QueryErrorResetBoundary>
            {({reset}) => (
                <ErrorBoundary
                    key={location.pathname}
                    onReset={reset}
                    onError={onError}
                    fallbackRender={({resetErrorBoundary, error}) => (
                        <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
                    )}
                >
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    )
}
