import {usePostHog} from 'posthog-js/react'
import * as Sentry from '@sentry/react'
import React from 'react'
import {useGet} from '~/api/query'

export const useProfile = () => {
    const posthog = usePostHog()
    const {data: profile} = useGet({endpoint: '/identity/profile'})

    React.useEffect(() => {
        if (!posthog._isIdentified() && profile.type === 'user') {
            posthog.identify(profile.id, {email: profile.email, active: profile.active})
            Sentry.setUser({email: profile.email, id: profile.id})
        } else if (profile.type === 'anonymous' && posthog._isIdentified()) {
            posthog.reset()
            Sentry.setUser(null)
        }
    }, [posthog, profile])

    return profile
}
