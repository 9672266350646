import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export const useHistoryState = <T>(key: string, defaultValue: T) => {
    const navigate = useNavigate()
    const state = useLocation().state || {}

    const value = state[key] ? JSON.parse(state[key]) : defaultValue

    const setValue = useCallback(
        (newValue: T) => {
            const currentValueStr = state[key]
            const newValueStr = JSON.stringify(newValue)

            if (currentValueStr === newValueStr) {
                return
            }

            navigate(
                {},
                {
                    replace: true,
                    state: {...state, [key]: newValueStr},
                },
            )
        },
        [key, navigate, state],
    )

    return [value as T, setValue] as const
}
