import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import {cn} from '~/lib/utils'

const TabsRoot = TabsPrimitive.Root

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({className, ...props}, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            'bg-muted text-muted-foreground inline-flex h-9 items-center justify-center rounded-lg p-1',
            className,
        )}
        {...props}
    />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({className, ...props}, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            'ring-offset-background focus-visible:ring-ring data-[state=active]:bg-background data-[state=active]:text-foreground inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow',
            className,
        )}
        {...props}
    />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({className, ...props}, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'ring-offset-background focus-visible:ring-ring mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
            className,
        )}
        {...props}
    />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

interface TabsProps<T extends string> {
    tabs: {value: T; label: string}[]
    selected: NoInfer<T>
    onChange: (value: T) => void
    tabContent?: Record<T, React.ReactNode>
    tabsClassName?: string
    tabButtonClassName?: string
}

const Tabs = <T extends string>({
    tabs,
    selected,
    onChange,
    tabContent,
    tabsClassName,
    tabButtonClassName,
}: TabsProps<T>) => {
    return (
        <TabsRoot value={selected} onValueChange={e => onChange(e as T)}>
            <TabsList className={tabsClassName}>
                {tabs.map(tab => (
                    <TabsTrigger key={tab.value} value={tab.value} className={tabButtonClassName}>
                        {tab.label}
                    </TabsTrigger>
                ))}
            </TabsList>
            {tabContent &&
                tabs.map(tab => (
                    <TabsContent key={tab.value} value={tab.value}>
                        {tabContent[tab.value]}
                    </TabsContent>
                ))}
        </TabsRoot>
    )
}

export {Tabs}
