import React, {Suspense} from 'react'
import {Helmet} from 'react-helmet-async'
import {Navigate, useNavigate} from 'react-router-dom'
import {useGet, useInvalidate, usePost} from '~/api/query'
import {Button} from '~/components/ui/button'
import {Formik} from 'formik'
import {Form, FormInput, FormStatus} from '~/components/ui/form'
import {toFormikValidate} from 'zod-formik-adapter'
import {z} from 'zod'
import {Card} from '~/components/ui/card'
import {PageBack} from '~/components/PageBack'
import {buildUrl} from '~/router'
import {useProfile} from '~/hooks/useProfile'
import Loading from '~/components/Loading'
import {assertNever} from '~/lib/utils'
import {GoogleOAuthButton} from '~/lib/GoogleOAuthButton'
import {Dialog, DialogContent, DialogTitle} from '~/components/ui/dialog'
import OauthPopup from 'react-oauth-popup'

const LoginSchema = z.object({
    email: z.string(),
    password: z.string(),
})

const ManualLoginForm: React.FC<{onSuccess: () => void}> = ({onSuccess}) => {
    const loginMutation = usePost('/identity/login')
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validate={toFormikValidate(LoginSchema)}
            onSubmit={async (values, {setSubmitting, setStatus}) => {
                setSubmitting(true)
                const response = await loginMutation.mutateAsync({
                    email: values.email,
                    password: values.password,
                })
                setSubmitting(false)
                if (response.type === 'user') {
                    onSuccess()
                    return
                } else if (response.type === 'login_error') {
                    const codeToMessage: Record<typeof response.code, string> = {
                        already_authenticated: 'You are already authenticated',
                        invalid_credentials: 'Invalid username or password',
                    }
                    setStatus(codeToMessage[response.code])
                    return
                }
                assertNever(response)
            }}
        >
            {({isSubmitting}) => (
                <Form className="w-full max-w-72">
                    <FormInput name="email" label="Email" type="email" />
                    <FormInput name="password" label="Password" type="password" />
                    <FormStatus />
                    <Button type="submit" disabled={isSubmitting} className="self-center">
                        Login
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

const OAuthLoginWithGoogle: React.FC<{onSuccess: () => void}> = ({onSuccess}) => {
    // const {initiateOAuth, isLoadingMessage} = useExternalOAuthPopup({onSuccess})

    // Preload the google login redirect URL. Safari doesn't allow popups started in an async context
    const {data: googleLoginData} = useGet({endpoint: '/identity/google-login-url'})
    const oAuthCallbackMutation = usePost('/identity/login/oauth-callback')

    React.useEffect(() => {
        if (oAuthCallbackMutation.isSuccess) {
            onSuccess()
        }
    }, [oAuthCallbackMutation.isSuccess])

    return (
        <OauthPopup
            url={googleLoginData.redirect_url}
            onCode={code => oAuthCallbackMutation.mutate({code})}
            onClose={() => void 0}
            title="Log in to Green Chip Data"
            width={450}
            height={600}
        >
            <div className="flex flex-col justify-start">
                <GoogleOAuthButton
                    disabled={oAuthCallbackMutation.isPending || oAuthCallbackMutation.isSuccess}
                    onClick={() => void 0}
                />
                {oAuthCallbackMutation.isPending ? (
                    <Loading loadingText="Logging in" className="mt-2 justify-center text-sm" />
                ) : null}
            </div>
        </OauthPopup>
    )
}

const Login: React.FC<{method: 'password' | 'google'; onSuccess: () => void}> = ({
    method,
    onSuccess,
}) => {
    const profile = useProfile()
    const invalidateQuery = useInvalidate()

    if (profile.type == 'user') {
        return null
    }

    const successCallback = () => {
        invalidateQuery('/identity/profile')
        invalidateQuery('/watchlist/')
        onSuccess()
    }

    return (
        <div className="flex flex-1 flex-col items-center justify-center text-center">
            <img src="/logo-rounded.svg" className="mx-auto my-8 h-32 w-32" alt="Green Chip Data" />
            <p className="mb-8">To continue, log in to Green Chip Data</p>
            <div className="min-h-20">
                <Suspense fallback={<Loading />}>
                    {method == 'password' ? (
                        <ManualLoginForm onSuccess={successCallback} />
                    ) : method === 'google' ? (
                        <OAuthLoginWithGoogle onSuccess={successCallback} />
                    ) : (
                        assertNever(method)
                    )}
                </Suspense>
            </div>
        </div>
    )
}

export const LoginModal: React.FC<{
    onSuccess: () => void
    isOpen: boolean
    setIsOpen: (open: boolean) => void
}> = ({onSuccess, isOpen, setIsOpen}) => {
    const successCallback = () => {
        setIsOpen(false)
        onSuccess()
    }
    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
                <DialogTitle className="sr-only">Login</DialogTitle>
                <Login method="google" onSuccess={successCallback} />
            </DialogContent>
        </Dialog>
    )
}

const StandaloneLoginPage: React.FC<{method: 'password' | 'google'}> = ({method}) => {
    const navigate = useNavigate()
    const profile = useProfile()

    if (profile.type == 'user') {
        return <Navigate to={buildUrl('/')} replace />
    }

    const onSuccess = () => {
        navigate(buildUrl('/'))
    }

    return (
        <div className="flex flex-1 flex-col">
            <Helmet>
                <title>Login | Green Chip Data</title>
                {/* Login with password is a private page, google's the only public method */}
                {method === 'password' && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>
            <PageBack />
            <Card className="mx-auto w-96 px-4 py-12">
                <Login method={method} onSuccess={onSuccess} />
            </Card>
        </div>
    )
}

export default StandaloneLoginPage
