import {humaniseSector} from '~/lib/utils'
import {Button} from '../ui/button'
import {Listing, SearchPayload} from '~/api/types'

type Sector = NonNullable<Listing['sector']>

const sectors: Sector[] = [
    'TECHNOLOGY',
    'FINANCE',
    'RETAIL_TRADE',
    'HEALTHCARE_AND_PHARMA',
    'PROPERTY',
    'CONSUMER',
    'MANUFACTORING',
    'INSURANCE',
    'OIL_AND_GAS',
    'MINERALS',
    'UTILITIES',
    'TRANSPORTATION',
    'COMMERCIAL_SERVICES',
    'COMMUNICATIONS',
    'OTHER',
] as const

export const FilterBySector = ({
    filterBy,
    setFilterBy,
}: {
    filterBy: SearchPayload
    setFilterBy: (payload: SearchPayload) => void
}) => {
    const current: Sector | undefined = filterBy.sector_filter?.sector

    const toggleSector = (sector: Sector) => {
        const newSector = sector === current ? undefined : sector

        if (!newSector) {
            setFilterBy({...filterBy, sector_filter: undefined})
        } else {
            setFilterBy({
                ...filterBy,
                sector_filter: {
                    sector: newSector,
                },
            })
        }
    }
    return (
        <div className="grid grid-cols-1 gap-2">
            {sectors.map(sector => (
                <Button
                    key={sector}
                    variant={sector === current ? 'default' : 'secondary'}
                    size="sm"
                    onClick={() => toggleSector(sector)}
                >
                    {humaniseSector(sector)}
                </Button>
            ))}
        </div>
    )
}
