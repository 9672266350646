import * as d3 from 'd3'
import {cn, formatLargeNumber} from '~/lib/utils'
import styles from './Chart.module.scss'
import {AxisBottom, AxisLeft} from '@visx/axis'
import {GridRows} from '@visx/grid'
import {AllGridRowsProps} from '@visx/grid/lib/grids/GridRows'
import {GridScale} from '@visx/grid/lib/types'

export const axisPadding = {
    x: 30,
    y: 60,
}

const formatXAxis = (num: number) => {
    const year = Math.floor(num.valueOf())
    const yearStr = `'${year % 1000}`
    const quarter = Math.floor((num.valueOf() - year) * 4) + 1
    return `Q${quarter} ${yearStr}`
}

interface AxisProps {
    width: number
    height: number
    xScale: d3.ScaleLinear<number, number, never> | d3.ScaleBand<number>
    yScale: d3.ScaleLinear<number, number, never>
    yAxisFormat?: (val: number) => string
}

const Axis = ({width, height, xScale, yScale, yAxisFormat = formatLargeNumber}: AxisProps) => {
    if (width === 0) {
        return
    }
    return (
        <>
            <ChartBackground width={width} height={height} yScale={yScale} padding={axisPadding} />
            <AxisBottom
                top={height - axisPadding.y}
                left={axisPadding.x}
                scale={xScale}
                tickFormat={val => formatXAxis(val.valueOf())}
                axisClassName={cn(styles.axisVx, styles.bottom)}
            />
            <AxisLeft
                scale={yScale}
                left={axisPadding.x}
                tickFormat={num => yAxisFormat(num.valueOf())}
                axisClassName={styles.axisVx}
            />
        </>
    )
}

interface ChartBackgroundProps {
    width: number
    height: number
    yScale: d3.ScaleLinear<number, number, never>
    padding?: {x: number; y: number}
    gridLines?: {numTicks: number; dasharray: string}
}

export const ChartBackground = ({
    width,
    height,
    yScale,
    padding = {x: 0, y: 0},
    strokeDasharray = '5',
    ...rest
}: ChartBackgroundProps & Omit<AllGridRowsProps<GridScale>, 'scale'>) => {
    return (
        <>
            <foreignObject
                className={styles.chartBg}
                x={padding.x}
                width={width - padding.x}
                height={height - padding.y}
            />
            <GridRows
                left={padding.x}
                scale={yScale}
                width={width - padding.x}
                height={height - padding.y}
                stroke="white"
                strokeWidth={0.5}
                strokeOpacity={0.3}
                strokeDasharray={strokeDasharray ?? '5'}
                {...rest}
            />
        </>
    )
}

export default Axis
