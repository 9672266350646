import {Helmet} from 'react-helmet-async'
import {useNavigate} from 'react-router-dom'
import {PageBack} from '~/components/PageBack'
import {Tabs} from '~/components/ui/tabs'
import {buildUrl} from '~/router'
import {Search} from './search'
import {AdvancedSearch} from './advanced-search'

const tabs = [
    {value: 'search' as const, label: 'Search'},
    {value: 'advanced-search' as const, label: 'Advanced Search'},
]

type Page = (typeof tabs)[number]['value']

export const SearchPageWrapper = ({page}: {page: Page}) => {
    const navigate = useNavigate()
    return (
        <div className="flex flex-1 flex-col items-stretch">
            <Helmet>
                <title>
                    {page === 'advanced-search' ? 'Advanced Search' : 'Search'} | Green Chip Data
                </title>
            </Helmet>
            <PageBack />
            <div className="mb-12 self-center">
                <Tabs
                    tabs={tabs}
                    tabButtonClassName="min-w-32"
                    selected={page}
                    onChange={newValue => {
                        navigate(
                            newValue === 'advanced-search'
                                ? buildUrl('search/advanced')
                                : buildUrl('search'),
                            {replace: true},
                        )
                    }}
                />
            </div>
            {page === 'advanced-search' ? <AdvancedSearch /> : <Search />}
        </div>
    )
}
