import {SearchPayload} from '~/api/types'
import {useSearchConfig} from './filter'
import {SliderRange, SliderRoot, SliderThumb, SliderTrack} from '../ui/slider'
import {cn, formatLargeNumber} from '~/lib/utils'
import {useState} from 'react'

export const FilterByMarketCap = ({
    filterBy,
    setFilterBy,
}: {
    filterBy: SearchPayload
    setFilterBy: (payload: SearchPayload) => void
}) => {
    const searchConfig = useSearchConfig()
    const lower = 0
    const upper = searchConfig.market_cap_max

    const current = filterBy.market_cap_filter

    const value: [number, number] = [current?.lower ?? lower, current?.upper ?? upper]

    return (
        <div className="w-72 sm:w-96">
            <RangeSlider
                minValue={0}
                maxValue={upper}
                steps={1000}
                scale="expontential"
                distribution={searchConfig.market_cap_distribution}
                value={value}
                onChange={newValues => {
                    let newLower: number | undefined = newValues[0]
                    let newUpper: number | undefined = newValues[1]
                    if (newLower === lower) {
                        newLower = undefined
                    }
                    if (newUpper === upper) {
                        newUpper = undefined
                    }

                    if (!newLower && !newUpper) {
                        setFilterBy({
                            ...filterBy,
                            market_cap_filter: undefined,
                        })
                    } else {
                        setFilterBy({
                            ...filterBy,
                            market_cap_filter: {
                                lower: newLower,
                                upper: newUpper,
                            },
                        })
                    }
                }}
            />
        </div>
    )
}

const distributionChartPadding = 80

// Display chart bars along the slider track
const TrackDistribution: React.FC<{
    distribution: number[]
    selectedRangePercents: [number, number]
}> = ({distribution, selectedRangePercents}) => {
    const maxValue = Math.max(...distribution)

    const [lowerSelected, upperSelected] = selectedRangePercents

    const numberOfDistributions = distribution.length

    return (
        <>
            <div
                className="absolute left-0 right-0 flex h-10 w-full items-end"
                style={{bottom: `calc(100% - ${distributionChartPadding}px)`}}
            >
                {distribution.map((value, index) => {
                    const localLower = ((index + 1) / numberOfDistributions) * 100
                    const localUpper = (index / numberOfDistributions) * 100
                    const isSelected = localLower >= lowerSelected && localUpper <= upperSelected

                    const distributionHeight =
                        (value / maxValue) * (distributionChartPadding - 10) + 10
                    return (
                        <div
                            key={index}
                            className={cn(
                                'rounded-t-md border transition-colors duration-75',
                                isSelected ? 'bg-accent-foreground/80' : 'bg-accent-foreground/20',
                            )}
                            style={{
                                height: distributionHeight,
                                width: `${100 / distribution.length}%`,
                            }}
                        />
                    )
                })}
            </div>
        </>
    )
}

interface RangeSliderProps {
    minValue: number
    maxValue: number
    scale: 'linear' | 'expontential'
    steps: number
    distribution: number[]
    value: [number, number]
    onChange: (values: [number, number]) => void
    formatNumber?: (value: number) => string
}

const RangeSlider: React.FC<RangeSliderProps> = ({
    minValue,
    maxValue,
    scale,
    steps,
    value,
    onChange,
    distribution,
}) => {
    const [values, setValues] = useState<[number, number]>([0, 100])

    const calculateExponentialValue = (normalizedValue: number): number => {
        // Using power function for exponential scaling
        const exponent = 2
        const normalized = Math.pow(normalizedValue, exponent)
        return minValue + normalized * (maxValue - minValue)
    }

    const calculateLinearValue = (normalizedValue: number): number => {
        return minValue + normalizedValue * (maxValue - minValue)
    }

    const handleValueChange = (newValues: [number, number]) => {
        const transformedValues = newValues.map(v => {
            const normalizedValue = v / 100
            const newValue =
                scale === 'expontential'
                    ? calculateExponentialValue(normalizedValue)
                    : calculateLinearValue(normalizedValue)

            return Math.round(newValue)
        }) as [number, number]

        setValues(newValues)
        onChange?.(transformedValues)
    }

    return (
        <div>
            <SliderRoot
                defaultValue={[0, 100]}
                onValueChange={handleValueChange}
                min={0}
                max={100}
                step={100 / steps}
                style={{paddingTop: distributionChartPadding}}
            >
                <TrackDistribution distribution={distribution} selectedRangePercents={values} />
                <SliderTrack>
                    <SliderRange />
                </SliderTrack>
                <SliderThumb />
                <SliderThumb />
            </SliderRoot>
            <div className="mt-2 flex justify-between">
                <span>{value[0] === minValue ? '-' : '$' + formatLargeNumber(value[0], true)}</span>
                <span>{value[1] === maxValue ? '-' : '$' + formatLargeNumber(value[1], true)}</span>
            </div>
        </div>
    )
}
