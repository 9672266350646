import {ColumnDef} from '@tanstack/react-table'
import {ChevronRight} from 'lucide-react'
import {SecuritiesUpcomingEarningsResponseUpcomingEarnings} from '~/api/types'
import {DataTable} from '~/components/ui/dataTable'
import {buildUrl} from '~/router'

const columns: ColumnDef<SecuritiesUpcomingEarningsResponseUpcomingEarnings['earnings'][number]>[] =
    [
        {
            accessorKey: 'security_name',
            header: 'Stock',
            meta: {
                className: 'w-full',
            },
        },
        {
            accessorKey: 'release_date',
            header: 'Date',
            cell: ({row}) => {
                return (
                    <span className="whitespace-nowrap">
                        {row.original.release_date.toFormat('yyyy-MM-dd')}
                    </span>
                )
            },
        },
        {
            id: 'link',
            cell: () => <ChevronRight className="w-4" />,
        },
    ]

function EarningsTable({response}: {response: SecuritiesUpcomingEarningsResponseUpcomingEarnings}) {
    return (
        <DataTable
            className="mx-auto max-w-sm"
            columns={columns}
            data={response.earnings}
            rowHref={row => buildUrl(':symbol', {symbol: row.security_symbol})}
        />
    )
}

export {EarningsTable}
