import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import {cn} from '~/lib/utils'

const SliderRoot = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({className, ...props}, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cn('relative flex w-full touch-none select-none items-center', className)}
        {...props}
    />
))
SliderRoot.displayName = SliderPrimitive.Root.displayName

const SliderThumb = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Thumb>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({className, ...props}, ref) => (
    <SliderPrimitive.Thumb
        ref={ref}
        className={cn(
            'border-primary/50 bg-background focus-visible:ring-ring block h-4 w-4 rounded-full border shadow transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50',
            className,
        )}
        {...props}
    />
))
SliderThumb.displayName = SliderPrimitive.Thumb.displayName

const SliderTrack = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Track>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({className, ...props}, ref) => (
    <SliderPrimitive.Track
        ref={ref}
        className={cn(
            'bg-primary/20 relative h-1.5 w-full grow overflow-hidden rounded-full',
            className,
        )}
        {...props}
    />
))
SliderTrack.displayName = SliderPrimitive.Track.displayName

const SliderRange = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Range>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({className, ...props}, ref) => (
    <SliderPrimitive.Range
        ref={ref}
        className={cn('bg-primary absolute h-full', className)}
        {...props}
    />
))
SliderRange.displayName = SliderPrimitive.Range.displayName

export {SliderRoot, SliderThumb, SliderTrack, SliderRange}
