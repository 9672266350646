import {Helmet} from 'react-helmet-async'
import {PageBack} from '~/components/PageBack'
import styles from './Privacy.module.scss'

export default function Privacy() {
    return (
        <>
            <PageBack />
            <Helmet>
                <title>Privacy Policy | Green Chip Data</title>
            </Helmet>
            <div className="mx-auto max-w-3xl text-sm">
                <h1>Privacy Policy</h1>
                <p>
                    This privacy policy applies to the Green Chip Data platform (hereby referred to
                    as "Platform") created by Green Chip Data (hereby referred to as "Service
                    Provider") as a Free service. This service is intended for use "AS IS".
                </p>

                <h3>Information Collection and Use</h3>
                <p>
                    The Platform collects information when you use it. This information may include
                    information such as
                </p>

                <ul>
                    <li>Your device's Internet Protocol address (e.g. IP address)</li>
                    <li>
                        The pages of the Platform that you visit, the time and date of your visit,
                        the time spent on those pages
                    </li>
                    <li>The time spent on the Platform</li>
                </ul>

                <p>
                    The Platform does not gather precise information about the location of your
                    device.
                </p>

                <p>
                    The Service Provider may use the information you provided to contact you from
                    time to time to provide you with important information, required notices and
                    marketing promotions.
                </p>

                <p>
                    For a better experience, while using the Platform, the Service Provider may
                    require you to provide us with certain personally identifiable information,
                    including but not limited to Email. The information that the Service Provider
                    request will be retained by them and used as described in this privacy policy.
                </p>

                <h3>Third Party Access</h3>
                <p>
                    Only aggregated, anonymized data is periodically transmitted to external
                    services to aid the Service Provider in improving the Platform and their
                    service. The Service Provider may share your information with third parties in
                    the ways that are described in this privacy statement.
                </p>
                <p>
                    Please note that the Platform utilizes third-party services that have their own
                    Privacy Policy about handling data. Below are the links to the Privacy Policy of
                    the third-party service providers used by the Platform:
                </p>

                <ul>
                    <li>
                        <a
                            className={styles.link}
                            href="https://posthog.com/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            PostHog
                        </a>
                    </li>
                    <li>
                        <a
                            className={styles.link}
                            href="https://sentry.io/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Sentry
                        </a>
                    </li>
                </ul>

                <p>
                    <strong>
                        The Service Provider may disclose User Provided and Automatically Collected
                        Information:{' '}
                    </strong>
                    as required by law, such as to comply with a subpoena, or similar legal process;
                    when they believe in good faith that disclosure is necessary to protect their
                    rights, protect your safety or the safety of others, investigate fraud, or
                    respond to a government request; with their trusted services providers who work
                    on their behalf, do not have an independent use of the information we disclose
                    to them, and have agreed to adhere to the rules set forth in this privacy
                    statement.
                </p>

                <h3>Opt-Out Rights</h3>
                <p>
                    You can stop all collection of information by contacting the Service Provider
                    email listed below.
                </p>

                <h3>Data Retention Policy</h3>
                <p>
                    The Service Provider will retain User Provided data for as long as you use the
                    Platform and for a reasonable time thereafter. If you'd like them to delete User
                    Provided Data that you have provided via the Platform, please contact them at
                    and they will respond in a reasonable time.
                </p>

                <h3>Children</h3>
                <p>
                    The Service Provider does not use the Platform to knowingly solicit data from or
                    market to children under the age of 13.
                </p>

                <p>
                    The Platform does not address anyone under the age of 13. The Service Provider
                    does not knowingly collect personally identifiable information from children
                    under 13 years of age. In the case the Service Provider discover that a child
                    under 13 has provided personal information, the Service Provider will
                    immediately delete this from their servers. If you are a parent or guardian and
                    you are aware that your child has provided us with personal information, please
                    contact the Service Provider so that they will be able to take the necessary
                    actions.
                </p>

                <h3>Security</h3>
                <p>
                    The Service Provider is concerned about safeguarding the confidentiality of your
                    information. The Service Provider provides physical, electronic, and procedural
                    safeguards to protect information the Service Provider processes and maintains.
                </p>

                <h3>Changes</h3>
                <p>
                    This Privacy Policy may be updated from time to time for any reason. The Service
                    Provider will notify you of any changes to the Privacy Policy by updating this
                    page with the new Privacy Policy. You are advised to consult this Privacy Policy
                    regularly for any changes, as continued use is deemed approval of all changes.
                </p>

                <p>This privacy policy is effective as of 2025-01-01</p>

                <h3>Your Consent</h3>
                <p>
                    By using the Platform, you are consenting to the processing of your information
                    as set forth in this Privacy Policy now and as amended by us.
                </p>

                <h3>Contact Us</h3>
                <p>
                    If you have any questions regarding privacy while using the Platform, or have
                    questions about the practices, please contact the Service Provider via email at{' '}
                    <a className={styles.link} href="mailto:hello@greenchipdata.com">
                        hello@greenchipdata.com
                    </a>
                    .
                </p>
            </div>
        </>
    )
}
