import React from 'react'
import {createBrowserRouter, Outlet, RouteObject, RouterProvider} from 'react-router-dom'
import GlobalWrapper from './GlobalWrapper'
import Index from './pages/index'
import ErrorPage from './components/ErrorPage'
import AdminRoot from './AdminRoot'
import Login from './pages/login'
import Signup from './pages/signup'
import AllSecurities from './pages/all-securities/AllSecurities'
import Disclaimer from './pages/disclaimer/disclaimer'
import Privacy from './pages/privacy/privacy'
import {SearchPageWrapper} from './pages/search/SearchPageWrapper'
const AnnualReport = React.lazy(() => import('./pages/annual-report'))
const AdminIndex = React.lazy(() => import('./pages/admin'))
const Benchmark = React.lazy(() => import('./pages/admin/Benchmark'))
const AdminSecurity = React.lazy(() => import('./pages/admin/AdminSecurity'))

const routes = [
    {
        path: '/',
        element: (
            <GlobalWrapper>
                <Outlet />
            </GlobalWrapper>
        ),
        errorElement: (
            <GlobalWrapper>
                <ErrorPage error={undefined} errorText="Sorry, we couldn't find that page" />
            </GlobalWrapper>
        ),
        children: [
            {
                index: true,
                Component: Index,
            },
            {
                path: 'all-securities',
                Component: AllSecurities,
            },
            {
                path: 'disclaimer',
                Component: Disclaimer,
            },
            {
                path: 'privacy',
                Component: Privacy,
            },
            {
                path: 'search',
                Component: () => <SearchPageWrapper page="search" />,
            },
            {
                path: 'search/advanced',
                Component: () => <SearchPageWrapper page="advanced-search" />,
            },
            {
                path: 'login',
                Component: () => <Login method="google" />,
            },
            {
                path: 'login/password',
                Component: () => <Login method="password" />,
            },
            {
                path: 'signup',
                Component: Signup,
            },
            {
                path: ':symbol',
                Component: AnnualReport,
            },
            {
                path: '*',
                element: (
                    <ErrorPage error={undefined} errorText="Sorry, we couldn't find that page" />
                ),
            },
        ],
    },
    {
        path: 'admin',
        Component: AdminRoot,
        children: [
            {
                index: true,
                Component: AdminIndex,
            },
            {
                path: 'benchmark',
                Component: Benchmark,
            },
            {
                path: ':ticker',
                Component: () => <AdminSecurity page="benchmark" />,
            },
            {
                path: ':ticker/raw_filings',
                Component: () => <AdminSecurity page="raw_filings" />,
            },
        ],
    },
] as const satisfies RouteObject[]

type RouteWithChildren = RouteObject & {children?: RouteObject[]}

type FlattenPaths<T extends RouteWithChildren[]> = T extends (infer R)[]
    ? R extends RouteWithChildren
        ? R['path'] extends string
            ?
                  | R['path']
                  | (R['children'] extends RouteObject[]
                        ? R['path'] extends '/'
                            ? FlattenPaths<R['children']>
                            : `${R['path']}/${FlattenPaths<R['children']>}`
                        : never)
            : never
        : never
    : never

// Paths as a flat TypeScript union of string literals
type Path = FlattenPaths<typeof routes>

type ExtractRouteParams<T> = string extends T
    ? Record<string, string>
    : T extends `${infer _Start}:${infer Param}/${infer Rest}`
      ? {[k in Param | keyof ExtractRouteParams<Rest>]: string}
      : T extends `${infer _Start}:${infer Param}`
        ? Record<Param, string>
        : {} | undefined

type PathParams<P extends Path> = ExtractRouteParams<P>

// Type-safe routes
// Found here: https://dev.to/0916dhkim/type-safe-usage-of-react-router-5c44
export const buildUrl = <P extends Path>(
    path: P,
    params: PathParams<P> = {} as PathParams<P>,
): string => {
    let ret: string = path
    if (params) {
        const paramObj: Record<string, string> = params
        for (const key of Object.keys(paramObj)) {
            ret = ret.replace(`:${key}`, paramObj[key])
        }
    }

    if (!ret.startsWith('/')) {
        return '/' + ret
    }
    return ret
}

const Router = () => {
    const router = createBrowserRouter(routes)

    return <RouterProvider router={router} />
}

export default Router
