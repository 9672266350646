import {Suspense} from 'react'
import {SearchPayload} from '~/api/types'
import StocksTable from '~/components/StocksTable'
import {useDebounceValue} from 'usehooks-ts'
import {post} from '~/api/query'
import {useSuspenseQuery} from '@tanstack/react-query'
import Loading from '~/components/Loading'
import {Filter} from '~/components/search/filter'
import {useHistoryState} from '~/hooks/useHistoryState'

export const Search = () => {
    const [filterBy, setFilterBy] = useHistoryState<SearchPayload>('search-filter', {
        sector_filter: undefined,
        market_cap_filter: undefined,
    })

    const [debouncedFilter] = useDebounceValue(filterBy, 500)

    return (
        <>
            <div className="mb-8 flex flex-1 flex-col">
                <h1 className="text-center">Search</h1>
                <Filter filterBy={filterBy} setFilterBy={setFilterBy} />
            </div>
            <h2>Results</h2>
            <Suspense fallback={<Loading />}>
                <SearchResults filter={debouncedFilter} />
            </Suspense>
        </>
    )
}

const SearchResults = ({filter}: {filter: SearchPayload}) => {
    const endpoint = '/search/'

    const {data} = useSuspenseQuery({
        queryKey: [endpoint, filter],
        queryFn: () => post(endpoint, {payload: filter}),
    })

    return <StocksTable listings={data.listings} />
}
