import {APIError} from '~/api/query'
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from './ui/card'
import {Button} from './ui/button'
import {useNavigate} from 'react-router-dom'
import React from 'react'

export default function ErrorPage({
    error,
    errorText,
    resetErrorBoundary,
}: {
    error?: unknown
    errorText?: string
    resetErrorBoundary?: () => void
}) {
    const navigate = useNavigate()

    React.useEffect(() => {
        console.error(error)
    }, [])

    const displayError = JSON.parse(JSON.stringify(error))
    if ('cause' in displayError) {
        delete displayError.cause // 'cause' doesn't look great
    }

    return (
        <div className="flex h-full w-full flex-col items-center justify-center">
            <Card>
                <CardHeader>
                    <CardTitle>An error occurred</CardTitle>
                </CardHeader>
                <CardContent>
                    {errorText && <p>{errorText}</p>}
                    {error instanceof APIError ? (
                        <pre className="mb-4 whitespace-pre-line">{displayError}</pre>
                    ) : error instanceof Error ? (
                        <pre className="mb-4 whitespace-pre-line">{error.message}</pre>
                    ) : null}
                </CardContent>
                {error instanceof APIError && error.status == 404 ? (
                    <CardFooter>
                        <Button onClick={() => navigate(-1)} variant="default">
                            Go Back
                        </Button>
                    </CardFooter>
                ) : (
                    resetErrorBoundary && (
                        <CardFooter>
                            <Button onClick={resetErrorBoundary} variant="default">
                                Try again
                            </Button>
                        </CardFooter>
                    )
                )}
            </Card>
        </div>
    )
}
