import './globals.scss'
import styles from './GlobalWrapper.module.scss'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {SuspenseAndBoundary} from './components/SuspenseAndBoundary'
import {BarChart4, Layers, Home, User, Menu} from 'lucide-react'
import DarkModeSelector from './components/DarkModeSelector'
import {useProfile} from './hooks/useProfile'
import {useInvalidate, usePost, usePreloadQueries} from './api/query'
import {Button} from './components/ui/button'
import {buildUrl} from './router'
import {DropdownLinks} from './components/ui/dropdown-menu'
import {Popover, PopoverContent, PopoverTrigger} from './components/ui/popover'
import {cn} from './lib/utils'

const SideNav: React.FC = () => {
    const profile = useProfile()
    const location = useLocation()

    const items = [
        {label: 'Home', href: buildUrl('/'), icon: Home},
        {label: 'All Stocks', href: buildUrl('all-securities'), icon: Layers},
        ...['AAPL', 'NVDA', 'MSFT', 'V'].map(symbol => ({
            label: symbol,
            href: buildUrl(':symbol', {symbol}),
            icon: BarChart4,
        })),
    ]

    const renderStockLinks = () => {
        return (
            <>
                <div className={styles.mobileLinks}>
                    {profile.type === 'anonymous' && (
                        <Button asChild size="sm" variant="ghost">
                            <Link to={buildUrl('login')}>
                                <User className="h-4" /> Login
                            </Link>
                        </Button>
                    )}
                    <DropdownLinks
                        className="dark"
                        links={[...items].map(item => ({
                            label: item.label,
                            href: item.href,
                            isActive: location.pathname == item.href,
                        }))}
                    >
                        <Menu />
                    </DropdownLinks>
                </div>
                <div className={styles.desktopLinks}>
                    {items.map((item, i) => (
                        <Link key={i} className={styles.navButton} to={item.href}>
                            <div className="hidden w-4 text-sm md:block">
                                <item.icon width={16} />
                            </div>
                            {item.label}
                        </Link>
                    ))}
                </div>
            </>
        )
    }

    return (
        <div className={styles.sideNav}>
            <div className={styles.sideNavInner}>
                <h2 className="border-muted-foreground mb-0 block justify-self-stretch py-3 pr-4 md:my-0 md:mb-2 md:w-full md:border-b md:py-6 md:pr-0">
                    <Link to={buildUrl('/')}>
                        <span className="sr-only">Home</span>
                        <div className="flex items-center justify-center gap-4 md:flex-col">
                            <img
                                src="/logo-4991e0e3.svg"
                                className="mx-auto block min-h-3 w-4 md:w-16"
                                alt="Green Chip Data"
                            />
                            <span className="text-center text-sm font-semibold md:text-base">
                                Green Chip Data
                            </span>
                        </div>
                    </Link>
                </h2>
                {renderStockLinks()}
                {profile.type === 'user' ? (
                    <ProfileDropdown>
                        <div className={styles.navButton}>
                            <div className="block w-4 text-sm">
                                <User width={16} />
                            </div>
                            <span className="hidden text-sm md:block">{profile.email}</span>
                        </div>
                    </ProfileDropdown>
                ) : profile.type === 'anonymous' ? (
                    <Link
                        className={cn(styles.navButton, styles.navButtonDesktopOnly)}
                        to={buildUrl('login')}
                    >
                        <div className="block w-4 text-sm">
                            <User width={16} />
                        </div>
                        <span className="hidden text-sm md:block">Login</span>
                    </Link>
                ) : null}
                <div className="flex items-center justify-center md:p-2">
                    <DarkModeSelector />
                </div>
            </div>
        </div>
    )
}

const ProfileDropdown: React.FC<{children: React.ReactNode}> = ({children}) => {
    const profile = useProfile()
    const navigate = useNavigate()
    const invalidateQuery = useInvalidate()
    const logoutMutation = usePost('/identity/logout')

    const doLogout = async () => {
        await logoutMutation.mutateAsync({})
        invalidateQuery('/identity/profile')
        navigate(buildUrl('/'))
    }

    if (profile.type !== 'user') {
        return null
    }

    return (
        <Popover>
            <PopoverTrigger autoFocus={false} tabIndex={-1} asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent
                className="left-0 flex max-w-40 flex-col items-stretch p-0 text-sm"
                align="center"
                side="right"
                autoFocus={false}
                onOpenAutoFocus={e => e.preventDefault()}
            >
                {profile.admin && (
                    <>
                        <Button
                            variant="ghost"
                            className="hover:bg-secondary"
                            onClick={() => navigate(buildUrl('admin'))}
                        >
                            Admin
                        </Button>
                        <div
                            role="separator"
                            aria-orientation="horizontal"
                            className="bg-border h-px"
                        />
                    </>
                )}
                <Button variant="ghost" className="hover:bg-secondary" onClick={doLogout}>
                    Logout
                </Button>
            </PopoverContent>
        </Popover>
    )
}

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="text-muted-foreground flex justify-center gap-4">
                <Link to={buildUrl('disclaimer')}>Disclaimer</Link>
                <Link to={buildUrl('privacy')}>Privacy</Link>
            </div>
        </footer>
    )
}

function GlobalWrapper({children}: {children: React.ReactNode}) {
    usePreloadQueries([
        {endpoint: '/identity/profile', payload: undefined},
        {endpoint: '/securities/', payload: {limit: 10}},
        {endpoint: '/securities/search-securities', payload: undefined},
        {endpoint: '/securities/upcoming-earnings', payload: undefined},
        {endpoint: '/watchlist/', payload: undefined},
        {endpoint: '/identity/google-login-url', payload: undefined},
    ])

    return (
        <div className={styles.pageWrap}>
            <SideNav />
            <div className={styles.page}>
                <SuspenseAndBoundary>
                    {children}
                    <Footer />
                </SuspenseAndBoundary>
            </div>
        </div>
    )
}

export default GlobalWrapper
