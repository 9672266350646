import React from 'react'
import {useEffect, useState} from 'react'
import {MoonIcon, SunIcon} from 'lucide-react'
import {Button} from './ui/button'

type Theme = 'dark' | 'light'

let sessionStartingValue = localStorage.getItem('darkMode') as Theme

if (sessionStartingValue !== 'dark' && sessionStartingValue !== 'light') {
    sessionStartingValue = 'light'
}

const DarkModeSelector: React.FC = () => {
    const [theme, setTheme] = useState<Theme>(sessionStartingValue)

    useEffect(() => {
        localStorage.setItem('darkMode', theme)
    }, [theme])

    useEffect(() => {
        window.document.body.classList.remove('dark')
        if (theme === 'dark') {
            window.document.body.classList.add('dark')
        }
    }, [theme])

    return (
        <Button
            className="px-2 md:px-4"
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            variant="ghost"
            title={'Dark mode: ' + (theme === 'light' ? 'light' : 'dark')}
        >
            {theme === 'dark' ? <MoonIcon className="h-4 w-4" /> : <SunIcon className="h-4 w-4" />}
            <span className="sr-only">Toggle dark mode</span>
        </Button>
    )
}

export default DarkModeSelector
