import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import {cn} from '~/lib/utils'
import {ChevronDown} from 'lucide-react'

const AccordionRoot = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn(
            'bg-secondary flex flex-col border-b first:rounded-t-md last:rounded-b-md last:border-none',
            className,
        )}
        {...props}
    />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitive.Header className="flex" asChild>
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'flex flex-1 items-center justify-between rounded-md p-4 text-left text-sm font-medium shadow-sm transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
                className,
            )}
            {...props}
        >
            {children}
            <ChevronDown className="text-muted-foreground h-4 w-4 shrink-0 transition-transform duration-200" />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down bg-popover overflow-hidden text-sm"
        {...props}
    >
        <div className={className}>{children}</div>
    </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

interface AccordionProps {
    items: {label: string; content: string}[]
    className?: string
    renderAsFAQSEO?: boolean
}

export const Accordion: React.FC<AccordionProps> = ({className, items, renderAsFAQSEO}) => {
    return (
        <>
            <AccordionRoot
                type="single"
                collapsible
                className={cn(className, 'rounded-md shadow-sm')}
            >
                {items.map((item, index) => (
                    <AccordionItem key={index} value={item.label}>
                        <AccordionTrigger className="px-4 shadow-sm hover:no-underline">
                            {item.label}
                        </AccordionTrigger>
                        <AccordionContent>
                            <div className="mx-4 my-5">{item.content}</div>
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </AccordionRoot>
            {renderAsFAQSEO ? (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'FAQPage',
                            mainEntity: items.map(item => ({
                                '@type': 'Question',
                                name: item.label,
                                acceptedAnswer: {
                                    '@type': 'Answer',
                                    text: item.content,
                                },
                            })),
                        }),
                    }}
                />
            ) : null}
        </>
    )
}
