import {ColumnDef} from '@tanstack/react-table'
import {formatLargeNumber} from '~/lib/utils'
import {Listing} from '~/api/types'
import {DataTable} from '~/components/ui/dataTable'
import SecurityImage from '~/components/ui/securityImage'
import {buildUrl} from '~/router'

const columns: ColumnDef<Listing>[] = [
    {
        accessorKey: 'symbol',
        header: 'Symbol',
        cell: ({row}) => {
            const original = row.original
            const imgUrl = original.icon_url
            const name = original.name

            let image: React.ReactNode
            if (imgUrl && name) {
                image = (
                    <SecurityImage
                        imgUrl={imgUrl}
                        alt={name}
                        className="h-8 w-8 max-w-8 border-none shadow-sm"
                    />
                )
            } else {
                image = <div className="h-8 w-8 max-w-8" />
            }

            return (
                <div className="flex items-center gap-4">
                    {image}
                    <div>{row.getValue('symbol')}</div>
                </div>
            )
        },
        meta: {
            className: 'sm:min-w-32',
        },
    },
    {
        accessorKey: 'name',
        header: 'Name',
        meta: {
            className: 'w-full',
        },
    },
    {
        accessorKey: 'market_cap',
        header: 'Market Cap',
        cell: ({row}) => <>{formatLargeNumber(row.getValue('market_cap'))}</>,
        meta: {
            className: 'min-w-28',
        },
    },
]

const StocksTable: React.FC<{listings: Listing[]; extraColumn?: ColumnDef<Listing>}> = ({
    listings,
    extraColumn,
}) => {
    return (
        <DataTable
            columns={[...columns, ...(extraColumn ? [extraColumn] : [])]}
            data={listings}
            rowHref={row => buildUrl(':symbol', {symbol: row.symbol})}
        />
    )
}

export default StocksTable
