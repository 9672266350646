import React from 'react'
import {cn} from '~/lib/utils'

const Loading: React.FC<{loadingText?: string; className?: string}> = ({
    loadingText = 'Loading',
    className,
}) => {
    const [iteration, setIteration] = React.useState(0)

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIteration(iteration => iteration + 1)
        }, 200)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className={cn('flex h-full w-full flex-1 items-center justify-center', className)}>
            {loadingText}
            <div className="ml-1 inline-block w-6 translate-y-1">
                {'\u00B7'.repeat((iteration % 3) + 1)}
            </div>
        </div>
    )
}

export default Loading
